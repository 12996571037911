@import 'animations.css';

/*app wide styles here */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Ginto';
  @apply text-black100;
}

h1,
h2,
h3,
h4 {
  @apply font-black;
}

h1 {
  @apply text-36;

  @media (min-width: theme('screens.sm')) {
    @apply text-130;
  }
}

h2 {
  @apply text-36;

  @media (min-width: theme('screens.sm')) {
    @apply text-48;
  }
}

h3 {
  @apply text-24;

  @media (min-width: theme('screens.sm')) {
    @apply text-36;
  }
}

h4 {
  @apply text-20;

  @media (min-width: theme('screens.sm')) {
    @apply text-24;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" xml:space="preserve"><path d="M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z"/><path fill="none" d="M0,0h24v24H0V0z"/></svg>');
  background-position: center right;
  background-repeat: no-repeat;
}

select,
input {
  @apply border-b;
  @apply border-alto;
  @apply py-10;
}

#custom-checkbox,
#custom-input-group input {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

label {
  transition: transform 150ms ease;
  transform-origin: left;
}

input,
textarea {
  @apply bg-transparent;

  &:focus {
    outline: none;
  }
}

button {
  @apply w-fit-content;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* custom classes here */

.ul-flex ul {
  display: flex;
  flex-direction: column;

  @media (min-width: theme('screens.xxl')) {
    display: flex;
    flex-direction: row;
  }
}

.li-margin li {
  margin-right: 30px;

  @media (min-width: theme('screens.md')) {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  @media (min-width: theme('screens.xxl')) {
    margin-bottom: 0 !important;
  }
}

.text-stroke {
  -webkit-text-stroke: 1px #14141f;
}

.text-stroke-white {
  -webkit-text-stroke: 1px #ffffff;
}

.h2-medium h2 {
  @apply text-24;

  @media (min-width: theme('screens.sm')) {
    @apply text-56;
    line-height: 1.2;
  }
}

.h2-large h2 {
  font-size: 24px;
  strong {
    @apply text-black100;
  }
  @media (min-width: theme('screens.sm')) {
    font-size: 64px;
    line-height: 1.1;
  }
}

.h2-larger h2 {
  font-size: 40px;
  line-height: 1.2;
  strong {
    @apply text-black100;
  }
  @media (min-width: theme('screens.sm')) {
    font-size: 64px;
    line-height: 1.1;
  }
}

.h2-mega h2 {
  font-size: 40px;
  line-height: 1.2;
  @media (min-width: theme('screens.sm')) {
    font-size: 64px;
  }
  @media (min-width: theme('screens.md')) {
    font-size: 86px;
    line-height: 1.1;
  }
  @media (min-width: theme('screens.lg')) {
    font-size: 110px;
    line-height: 1.1;
  }
}

.heading-strong strong {
  color: black;
}

.heading-strong-white strong {
  color: white;
}

.ginto-nord {
  font-family: 'Ginto Nord';
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar ::-webkit-scrollbar {
  display: none;
}

input:required {
  box-shadow: none;
}

.autofill-transparent {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    box-shadow: 0 0 0 30px theme('colors.black100') inset !important;
    -webkit-text-fill-color: white !important;
  }
  &:-webkit-autofill::first-line {
    font-size: 15px !important;
    @apply font-body;
    @media (min-width: theme('screens.sm')) {
      font-size: 24px !important;
    }
  }
}

.heading-link a {
  @apply text-black underline;
  text-decoration-thickness: 1px !important;
  text-underline-offset: 0.2em;
  &:hover {
    @apply no-underline;
  }
}

.list-dash ul li {
  display: flex;
  &::before {
    content: '—';
    font-size: 18px;
    transform: translateY(-2px);
    margin-right: 6px;
  }
}

.cta-columns-bg-link p a {
  @apply font-heading;
  @apply text-12;
  @apply tracking-widest;
  @apply ml-2;

  @media (min-width: theme('screens.sm')) {
    @apply text-13;
    @apply tracking-mid-wider;
    @apply ml-4;
  }
}

.richtext-strong strong {
  @apply font-heading;
  @apply mx-3;
  @apply text-17;
}

.richtext-link a {
  @apply border-b;
  @apply pb-1;
  &:hover {
    @apply border-none;
  }
}

.nav-item:hover,
.nav-item:hover > div > div {
  opacity: 1 !important;
  transition: all 0.5s;
}

.country-list {
  background-color: #02020f !important;
  color: white !important;
  border: 0 !important;
}

.blog-content h4 {
  font-family: Ginto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 160%;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding-left: 40px;
  border-left: 2px solid #14141f;
  margin-left: 25px;
}

.blog-content img {
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: 1024px) {
    max-width: 1060px;
  }
}

.blog-content li {
  list-style: inside disc;
}

.blog-content li:last-child {
  margin-bottom: 24px;
}

.blog-content h4 {
  margin: 100px auto 40px auto;
  @media (min-width: 1024px) {
    margin: 100px -170px 40px -170px;
  }
  max-width: 1060px;
}

.blog-content h1 {
  margin: 100px auto 40px auto;
  font-size: 48px;
  line-height: 130%;
}

.blog-content h2 {
  margin: 100px auto 40px auto;
  font-size: 32px;
  line-height: 160%;
}

.blog-content h3 {
  margin: 100px auto 40px auto;
}

.blog-content img {
  margin: 100px auto 24px auto;
  width: 1060px;
}

.blog-content p,
.podcast-content p {
  margin-bottom: 21px;
}

.blog-content a,
.podcast-content a {
  text-decoration: underline;
}

.blog-content a:hover,
.podcast-content a:hover {
  text-decoration: none;
}

.blog-landing-article-title {
  margin-top: 16px;
}

.blog-landing-article-title strong {
  font-weight: normal;
}

/* Cookies banner */

#hs-eu-cookie-confirmation-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  max-width: 1000px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

#hs-en-cookie-confirmation-buttons-area,
div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom
  > div#hs-eu-cookie-confirmation-inner
  > div#hs-en-cookie-confirmation-buttons-area,
#hs-eu-policy-wording,
#hs-eu-policy-wording > p,
#hs-eu-cookie-disclaimer,
div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p#hs-eu-cookie-disclaimer {
  margin: 0 !important;
  font-size: 13px;
  font-family: 'Ginto';
}

@media screen and (min-width: 768px) {
  #hs-eu-policy-wording {
    padding-right: 40px;
  }

  #hs-en-cookie-confirmation-buttons-area {
    padding-left: 90px;
  }
}

@media screen and (max-width: 768px) {
  #hs-en-cookie-confirmation-buttons-area {
    padding-top: 10px;
  }
}

@media screen and (min-width: 475px) and (max-width: 768px) {
  #hs-eu-policy-wording {
    padding-bottom: 10px;
  }
}

div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom
  div#hs-eu-cookie-confirmation-inner
  a#hs-eu-confirmation-button,
div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom
  div#hs-eu-cookie-confirmation-inner
  a#hs-eu-decline-button {
  border-radius: 0 !important;
  font-size: 13px;
}

#hs-en-cookie-confirmation-buttons-area > #hs-eu-confirmation-button {
  margin-top: -10px;
}

.chat-widget {
  width: 320px;
}

#hubspot-messages-iframe-container.widget-align-right {
  z-index: 100;
}

.webinar-content h2,
.ebook-content h2 {
  font-size: 42px;
  margin-top: 50px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 26px;
    margin-bottom: 24px;
  }
}

.ebook-content h2:first-child,
.webinar-content h2:first-child {
  margin-top: 0;
}

.ebook-content ul,
.webinar-content ul {
  margin-bottom: 60px;
}

.ebook-content li,
.webinar-content li {
  list-style-type: '— ';
  padding-bottom: 15px;
  list-style-position: outside;
  margin-left: 20px;
}

.webinar-content p,
.webinar-content li,
.ebook-content p,
.ebook-content li {
  @media (max-width: 768px) {
    font-size: 17px;
  }
}

.webinar-content img {
  margin-bottom: 64px;
}

.PhoneInputCountrySelect {
  font-size: initial;
}

.country:hover,
.country-list > .highlight {
  background-color: #14141f !important;
}

.search-box {
  width: calc(100% - 10px);
  border: none !important;
  background-color: #14141f !important;
  border-radius: 0 !important;
  padding: 8px 12px !important;
  margin: 0 !important;
}

.country {
  padding: 0 8px;
}

.country-list > .divider {
  border-bottom: 1px solid #434343 !important;
}

.flag-dropdown:hover,
.flag-dropdown.open {
  background: transparent !important;
}

.landing-page-input::placeholder {
  color: #14141f;
  opacity: 0.4;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.quote {
  @apply relative;

  p:not(:last-child) {
    @apply mb-20;
  }

  p:first-child::before {
    content: '\201C';

    @apply relative;
  }

  p:last-child {
    @apply inline;

    &::after {
      content: '\201D';

      @apply relative;
    }
  }

  @media (min-width: 1024px) {
    p:not(:last-child) {
      @apply mb-30;
    }

    p:first-child::before {
      @apply absolute top-0 -left-10;
    }
  }
}

.controls {
  display: flex;
  align-items: center;
}

.timebar {
  flex: 1 1;
  position: relative;
}

.timebar-pin {
  transform: translate(-50%, calc(-50% + 2px));
}

.timebar-time-info {
  top: -12px;
  right: -64px;
}

.hamburger svg {
  stroke: #000;
  transition: 0.2s;
}
.hamburger svg path {
  transition: transform 0.2s;
  transform: rotate(0deg) translateY(0px) scale(1);
  transform-origin: center;
}
.hamburger.menu-open svg path:first-child {
  transform: translate(10px, 0) rotate(-45deg);
}
.hamburger.menu-open svg path:last-child {
  transform: translate(-2px, -22px) rotate(45deg) scaleX(1.7);
}

.richtext-no-bold strong {
  @apply font-normal;
}

.richtext-link a {
  color: #888888;
}

.video-embed {
  @apply relative h-0 overflow-hidden;

  iframe {
    @apply absolute top-0 left-0 right-0 bottom-0 h-full w-full;
  }
}

.video-bg-container {
  @screen md {
    height: calc(100% - var(--padding-md));
  }

  @screen lg {
    height: calc(100% - var(--padding-lg));
  }
}

.slider-image {
  @apply max-h-800 min-h-400 xxs:h-40%-screen md:h-80%-screen;

  @media (min-height: 0px) {
    aspect-ratio: 3/4;
  }
  @media (min-height: 926px) {
    aspect-ratio: 19/25;
  }
  @media (min-height: 1024px) {
    aspect-ratio: 7/10;
  }
}

.filler {
  width: 395px;
  flex-shrink: 0;
  width: calc((100vw - 1280px) / 2 - 64px);
  @media (min-width: 640px) {
    margin-right: -10px;
  }
  @media (min-width: 768px) {
    margin-right: -45px;
  }
  @media (min-width: 1023px) {
    margin-right: 20px;
  }
  @media (min-width: 1440px) {
    margin-right: 0px;
  }
}

.adjusted-container {
  width: calc(90vw);
  @media (min-width: 0px) {
    padding-left: 15px;
  }
  @media (min-width: 1024px) {
    padding-left: 80px;
  }
  @media (min-width: 1440px) {
    padding-left: calc((100vw - 1280px) / 2);
  }
}
